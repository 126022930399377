@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

// mat.$theme-ignore-duplication-warnings: true;

@include mat.core();

$light-primary: mat.define-palette(mat.$grey-palette, 500, 400, 600);
$light-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);
$dark-primary: mat.define-palette(mat.$grey-palette, 700, 600, 800);
$dark-accent: mat.define-palette(mat.$grey-palette, A400, A200, A700);
$default-warn: mat.define-palette(mat.$red-palette);

$custom-typography-config: mat.define-typography-config(
  $body-1: mat.define-typography-level(inherit, inherit, inherit, $font-family: 'Quicksand'),
);

$light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
    warn: $default-warn,
  ),
  typography: $custom-typography-config,
  density: 0,
));

$dark-theme: mat.define-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $default-warn,
  ),
  typography: $custom-typography-config,
  density: 0,
));

html, body { height: 100%; scroll-behavior: smooth; }
body { margin: 0; font-family: Quicksand, sans-serif; }

@include mat.core-color($light-theme);
@include mat.all-component-themes($light-theme);
@include mat.all-component-typographies($light-theme);
@include mat.strong-focus-indicators-theme($light-theme);

.dark-theme {
  @include mat.core-color($dark-theme);
  @include mat.all-component-colors($dark-theme);
  @include mat.tabs-color($light-theme);
}

//@media (prefers-color-scheme: light) {
//  //@include mat.core-color($light-theme);
//  //@include mat.all-component-themes($light-theme);
//  //@include mat.all-component-typographies($light-theme);
//  //@include mat.strong-focus-indicators-theme($light-theme);
//
//  @include mat.all-component-colors($light-theme);
//}

@media only screen and (max-width: 1300px) {
  body {
    background-color: transparent;
  }
}
